import c0 from './autosize_controller';
import c1 from './file_upload_controller';
import c2 from './form_hide_element_controller';
import c3 from './password_peek_controller';
import c4 from './swal_confirm_controller';
import c5 from './toggle_controller';
export const definitions = [
	{identifier: 'autosize', controllerConstructor: c0},
	{identifier: 'file-upload', controllerConstructor: c1},
	{identifier: 'form-hide-element', controllerConstructor: c2},
	{identifier: 'password-peek', controllerConstructor: c3},
	{identifier: 'swal-confirm', controllerConstructor: c4},
	{identifier: 'toggle', controllerConstructor: c5},
];
