import { Application } from "@hotwired/stimulus";
import AccordionController from "./../../../components/accordion_component/accordion_controller";
import TabsController from "./../../../components/tabs_component/tabs_controller";
import ScrollReveal from '@stimulus-components/scroll-reveal'
import ScrollTo from '@stimulus-components/scroll-to'

const application = Application.start();

// Configure Stimulus development experience
application.warnings = true;
application.debug = false;
window.Stimulus = application;

Stimulus.register("accordion", AccordionController);
Stimulus.register("tabs", TabsController);
Stimulus.register('scroll-reveal', ScrollReveal)
Stimulus.register('scroll-to', ScrollTo)

export { application };
