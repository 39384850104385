import { Controller } from '@hotwired/stimulus';
export default class extends Controller {

  static targets = ['button'];

  connect() {
    this.buttonTarget.addEventListener('click', () => this.togglePeekPassword());
  }

  togglePeekPassword() {
    const passwordinput = this.element.querySelector('input');
    if (passwordinput.type === 'password') {
      this.buttonTarget.querySelector('svg').classList.remove('fa-eye-slash');
      this.buttonTarget.querySelector('svg').classList.add('fa-eye');
      passwordinput.type = 'text';
    } else {
      passwordinput.type = 'password';
      this.buttonTarget.querySelector('svg').classList.add('fa-eye-slash');
      this.buttonTarget.querySelector('svg').classList.remove('fa-eye');
    }
  }
}
