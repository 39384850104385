import { Controller } from '@hotwired/stimulus';

// Controller to class toggle the hide element target, based on the selected values
export default class extends Controller {
  static targets = [ "hideElement"]
  toggleClass = '.hidden';
  selectorRequirementValue = 0;
  selectorArray = []
  textSelectorInput = null


  // Update the selector requirement value, based on the hide element's data value
  hideElementTargetConnected(element) {
    this.selectorRequirementValue = this.hideElementTarget.dataset.hideValue
  }

  connect() {
    // Fetch the required inputs and add to the selector array
    this.selectorArray.push(...document.querySelectorAll('input[type="radio"]'))
    this.selectorArray.push(...document.querySelectorAll('input[type="checkbox"]'))

    this.selectorArray.forEach((input) => {
      input.addEventListener('change', (e) => this.selectorUpdated(e))

      if(input.value == this.selectorRequirementValue) {
        this.textSelectorInput = input
      }
    });

    this.selectorUpdated()
  }

  // Run on any selector change
  selectorUpdated(e) {
    let requirementValueSelected = false

    this.selectorArray.forEach((input) => {
      if (input.checked && input.value == this.selectorRequirementValue) {
        requirementValueSelected = true
      }
    })

    if (this.hasHideElementTarget) {
      if (requirementValueSelected) {
        this.hideElementTarget.classList.remove("hidden")

        // Flag the nested input as required
        this.hideElementTarget.children[0].children[1].required = true

      } else {
        this.hideElementTarget.classList.add("hidden");

        // Flag the nested input as not required
        this.hideElementTarget.children[0].children[1].required = false

      }
    }

    // Focus on the text input field if this option selector was the one selected, and it is checked
    if(e && e.target == this.textSelectorInput && this.textSelectorInput.checked) {
      this.hideElementTarget.children[0].children[1].focus()
    }
  }

}
