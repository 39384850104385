import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["content"];

  toggle(event) {
    this.contentTargets.forEach((t) => t.classList.toggle('hidden'));
    event.currentTarget.classList.toggle('active');

  }

}
